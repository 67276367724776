
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function PropertyStructuredDataNewHomes({ property, pageurl }) {
  const cloud_url = process.env.GATSBY_CLOUD_URL;
  const site_url = process.env.GATSBY_SITE_URL; 
  var ldJson = {
    "@context": "http://schema.org",
    "@type": "Residence",
    "address" : property.development_title,
    "description" : property.short_description,
    "name" :  `${property.bedrooms} beds in ${property.development_title}`,
    "photo" :  property.images[0]?.url,
    "mainEntityOfPage": {
      "@type": "ItemPage",
      "@id": `${pageurl}`
    }
  };
  return (
    <Helmet>
      <script defer type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default PropertyStructuredDataNewHomes
